html, body {
	overflow: auto !important;
}

.vg-fieldset {
	border: none;
	margin-top: 1em;
	padding-top: 16px;
}

.vg-fieldset legend {
	font-size: 14pt;
	color: #0E0554;
	font-weight: lighter;
}

.v-select:not(.theme--dark),
.v-text-field:not(.theme--dark)
{
	background-color: #fff;
}

.v-input.theme--dark fieldset {
	border-color: #fff;
}

.v-input.theme--dark.error--text fieldset {
	border-color: red;
}

.destacado .v-input.theme--dark fieldset {
	background-color: #0e0554;
	border: none;
}

.v-list {
	padding-top: 0;
}

.loader {
	display: none;
}